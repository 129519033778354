import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';


const ConsoleNav = () => {
 return (
        <div className="navbar-conatainer">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/"><img src={logo} alt="Dopshub" title="Dopshub" width="120" /></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/">Menu1</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Menu2</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Menu3</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Menu4</Link>
                                    </li>
                                </ul>
                            </div> */}
                            <ul className="nav nav-dropdown pull-right">
                                <li className="dropdown"><i className="fa fa-search"></i></li>
                                <li className="dropdown"><i className="fa fa-spinner fa-spin"></i></li>
                                <li className="dropdown"><i className="fa fa-cog"></i></li>
                                <li className="dropdown"><i className="fa fa-telegram"></i></li>
                                <li className="dropdown"><i className="fa fa-bell-o"></i></li>
                            </ul>
                        </div>
                    </nav>
                </div>
 )
}

export default ConsoleNav;