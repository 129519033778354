import React, { Component } from 'react';
import './css/App.css';
import Home from './pages/home';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Login from "./pages/login";
import { BrowserRouter, Route } from "react-router-dom";
import Register from "./pages/register";
import Dashboard from './pages/dashboard';
import Deployments from './pages/deployments'
class App extends Component{
  render(){
    return (
      <BrowserRouter>
        <div className="App">
          <Route path="/dashboard" component={Dashboard} />
          <Route exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/deployments" component={Deployments} />

        </div>
      </BrowserRouter>
    );
  }
}

export default App;

