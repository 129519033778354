import React from 'react';
import { Link } from 'react-router-dom';
import { Deployments } from '../pages/deployments'
const Sidebar = () => {
 return (
    <div className="sidebar">
        <div className="side_Menus">
            <ul className="side-nav accordion_mnu collapsible">
            <li>
                    <Link to="#">
                        <span className="icons">
                            <i className="fa fa-laptop"></i>
                        </span>
                        Dashboard
                    </Link>
                </li>
                <li>
                    <Link to="/deployments">
                        <span className="icons">
                            <i className="fa fa-delicious"></i>
                        </span>
                        Deployment
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        <span className="icons">
                            <i className="fa fa-american-sign-language-interpreting"></i>
                        </span>
                        User Integration
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        <span className="icons">
                            <i className="fa fa-rub"></i>
                        </span>
                        User Billing
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        <span className="icons">
                            <i className="fa fa-users"></i>
                        </span>
                        Profile
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        <span className="icons">
                            <i className="fa fa-wrench"></i>
                        </span>
                        Account Setting
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        <span className="icons">
                            <i className="fa fa-hourglass-end"></i>
                        </span>
                        Statistics
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        <span className="icons">
                            <i className="fa fa-envelope-o"></i>
                        </span>
                        Support
                    </Link>
                </li>
            </ul>
        </div>
    </div>
 )
}

export default Sidebar;