import React from 'react';
import { Link } from 'react-router-dom';

class Register extends React.Component {
    render() {
        return (
            <div className="Register">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="form-container">
                                <form>
                                    <div className="form-header">
                                        <h5 className="form-title text-center">Sign Up</h5>
                                    </div>
                                    <div className="form-content">
                                        <div className="form-group">
                                            <label for="username">Username</label>
                                            <input type="text" className="form-control" id="username" autoFocus required/>
                                        </div>
                                        <div className="form-group">
                                            <label for="email">Email</label>
                                            <input type="email" className="form-control" id="email" required/>
                                        </div>
                                        <div className="form-group">
                                            <label for="pwd">Password</label>
                                            <input type="password" className="form-control" id="pwd" required/>
                                        </div>
                                        <div className="form-group form-check">
                                            <label className="form-check-label">
                                            <input className="form-check-input" type="checkbox" /> 
                                                I have read & agree to the <a href="/">Terms of Service.</a>
                                            </label>
                                        </div>
                                        <button type="submit" className="btn btn-lg btn-danger">Sign Up</button>
                                        <div className="form-links text-center">
                                            <Link to="/login" >Already Have an Account? Log In</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;