import React from 'react';

class Footer extends React.Component {
  render() {
    return (
        <footer>
            <div className="Footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5 footer-navbar1">
                            <h3>Dopshub</h3>
                            <address>
                                <a href=''></a>Comming soon<br/> 
                                <br/>
                                <br/>
                                <br/>
                            </address>
                            <h5>Follow Us</h5>
                            <a href="/">Facebook</a>
                            <a href="/">Twitter</a>
                            <a href="/">Github</a>
                            <a href="/">Instagram</a>
                            <a href="/">Linkendin</a>
                        </div>
                        <div className="col-md-7 footer-navbar">
                            <div className="row">
                                <div className="col-md-3 col-xs-12">
                                    <h5>What</h5> 
                                    <a href="/about">About us</a> 
                                    <a href="/clients">Clients</a> 
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <h5>Services</h5> 
                                    <a href="/">Automation Tools</a> 
                                    <a href="/"></a> 
                                    <a href="/">Solutions on deamand</a>
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <h5>Developers</h5> 
                                    <a href="/">Scrapy Deployment</a> 
                                    <a href="/">Periodic Tasks</a> 
                                </div>
                                <div className="col-md-3 col-xs-12">
                                    <h5>Resources</h5> 
                                    <a href="/">Blog</a> 
                                    <a href="/">Support</a> 
                                    <a href="/">Terms of Service</a> 
                                    <a href="/">Abuse Report</a> 
                                    <a href="/">Privacy Policy</a> 
                                    <a href="/">Cookie Policy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="copyright">&copy; 2019 Dopshub</p>
                </div>
            </div>
      </footer>
    );
  }
}

export default Footer;