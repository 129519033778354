import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        isOpen: false
      }

      this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    return (
    <header>
        <div className="Header">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid main-nav">
                <Link className="navbar-brand" to="/"><img src={logo} alt="Dopshub" title="Dopshub" width="120" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

                <span className="navbar-toggler-icon"></span>
                </button>

                <div isOpen={this.state.isOpen} className="navbar-collapse">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                    <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/">Our Story</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/">Services</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/">What's new</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/">Help and Support</Link>
                    </li>
                </ul>
                <div className="account-container">
                    <Link to="/login"><button className="btn btn-danger" id="Signin">Login To Console</button></Link>
                </div>
                </div>
                </div>
            </nav>
      </div>
    </header>
    );
  }
}

export default Header;