import React, { Component } from 'react';
// import styled from 'styled-components';
import Sidebar from '../components/sidebar';
import ConsoleNav from '../components/consoleNav';
import MainContents from '../components/main-content';

// const NavBar = styled.div`
// background:skyblue;
// `;

class Console extends Component {
    render() {
        return (
            <div className="Console">
                <ConsoleNav />
                <Sidebar />
                {/* <MainContents /> */}
            </div>
        )
    }
}

export default Console;