import React from 'react';
import Bg from '../images/bg1.gif';
import Header from '../components/header';
import Footer from '../components/footer';
var sectionStyle = {
    width: "100%",
    backgroundImage: `url(${Bg})`
};

class Home extends React.Component {
    render() {
        return (
            <div className="Home">
            <Header />
                <div className="hero-container text-center" style={ sectionStyle }>
                    <div className="container-fluid">
                        <div className="home-content-header col-md-6 offset-md-3">
                            <h1>To Get Deployed</h1>
                            <p>Run your crawlers smoothly and prevent them to get blocked.</p>
                            <button className="btn btn-outline-danger">Read more</button>
                        </div>
                    </div>
                </div>
                <section className="home-section home-data-services">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-7">
                                <h2>Deploy Scrapy</h2>
                                <p></p>
                                <p align="justify">Running Scrapy instances over cloud seems a mess when it comes to managing them to run periodically and monitor actively. If somehow anyone manages then infrastructure requires an immense cost that does not suit to business model.</p>
                                <p align="justify">What if you invest few minutes to deploy your scrapy project on Dopshub and run concurrent crawl processes and get DEBUG level log monitoring, downloading of item feeds, and much more with attractive pricing.</p>
                                <p align="justify">We especially worked to reduce the cost and offer you all possible features via user-friendly UI and Web API. If there is something that we missed and you want that included on priority or you have a different use case, then post us by clicking below button.</p>

                                <div className="btn-container">
                                    <form action="https://formspree.io/samarpit@dopshub.com" method="POST">
                                    <input type="email" placeholder="Your email" name="_replyto" className="form-control mb-3" autoFocus required/>
                                    <textarea name="message" placeholder="Tell us about your project." className="form-control" />
                                    <input className="btn btn-danger" type="submit" value="Send"/>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="img-container">
                                    <img src="https://dummyimage.com/515x400/f0f0f0/fff" alt="Data on Demand" width="100%" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="home-section home-scrapy-cloud">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="img-container">
                                    <img src="https://dummyimage.com/515x400/f0f0f0/fff" alt="Scrapy" width="100%" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <h2>Automation Tools</h2>
                                <p>We consider if have you have to perform an operation repetitively then It should be automated.</p>
                                <div className="btn-container">
                                    <button className="btn btn-outline-danger">Read more</button>
                                    <button className="btn btn-danger">Sign up for free</button>
                                </div>  
                            </div>
                        </div>
                    </div>
                </section> */}
                <Footer />
            </div>
        );
    }
}

export default Home;