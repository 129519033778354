import React, { Component } from 'react';
import Console from '../containers/console';
import { Link } from 'react-router-dom';

class Deployments extends Component {
    render () {
        return (
            <div className="Console">
                <Console/>
                <div className="Deployment_table main-contents">
                    <div className="main">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active">Deployments</li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Sr. No.</th>
                                        <th>Deployment Name</th>
                                        <th>Deployment Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Abc</td>
                                        <td>Scrappy</td>
                                        <td>Active</td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Abc</td>
                                        <td>Scrappy</td>
                                        <td>Active</td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>Abc</td>
                                        <td>Scrappy</td>
                                        <td>Active</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}


export default Deployments;